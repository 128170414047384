<template>
  <b-tr>
    <b-td class="white-space-nowrap">
      {{abi.abi}}
      <br>
      {{abi.description}}
    </b-td>
    <b-td class="white-space-nowrap">
      {{plafondTypeComputed}}
    </b-td>
    <b-td class="white-space-nowrap">
      {{plafondLimit}}
    </b-td>
    <b-td class="white-space-nowrap">
      {{plafondUsage}}
    </b-td>
    <td>{{startDate}}</td>
    <td>{{endDate}}</td>
<!--    <b-td class="white-space-nowrap table-cell&#45;&#45;compact text-right">-->
<!--      <b-button-->
<!--        variant="danger"-->
<!--        @click="doDelete"-->
<!--        size="sm"-->
<!--      >-->
<!--        <font-awesome-icon icon="times"/>-->
<!--      </b-button>-->
<!--    </b-td>-->
  </b-tr>
</template>

<script>
import { formatCurrency, formatDateOnly } from '../../utils/formatterHelper';
// import { toastFailure, toastSuccess } from '../../utils/toast';

export default {
  name: 'UserPlafondRow',
  props: {
    userPlafond: Object,
  },
  data() {
    let abi;
    if (this.userPlafond.bank) {
      abi = this.userPlafond.bank.abi;
    } else {
      abi = this.$store.getters['tableUtils/getAbi'](this.userPlafond.bankId);
    }
    return {
      abi,
    };
  },
  computed: {
    plafondLimit() {
      return formatCurrency(this.userPlafond.globalPlafondLimit);
    },
    plafondUsage() {
      return formatCurrency(this.userPlafond.agreedPurchase);
    },
    startDate() {
      return formatDateOnly(this.userPlafond.validityStart);
    },
    endDate() {
      return formatDateOnly(this.userPlafond.validityEnd);
    },
    plafondTypeComputed() {
      let val = 'N.D.';
      const types = this.$store.getters['tableUtils/getPlafondTypes'];
      if (types.length > 0) {
        const typeFound = types.filter(type => type.key === this.userPlafond.plafondType);
        val = typeFound[0].label;
      }
      return val;
    },
  },
  methods: {
    // doDelete() {
    //   console.log('delete action');
    //   this.$confirm(
    //     {
    //       message: "Procedere con l'eliminazione del plafond?",
    //       button: {
    //         no: 'No',
    //         yes: 'Si',
    //       },
    //       /**
    //        * Callback Function
    //        * @param {Boolean} confirm
    //        */
    //       callback: (confirm) => {
    //         if (confirm) {
    //           const entityId = this.userPlafond.plafondId;
    //           this.$store.dispatch('invoice/deletePlafond', entityId).then(() => {
    //             toastSuccess('Plafond eliminato con successo');
    //           },
    //           (error) => {
    //             console.log('delete error', error);
    //             toastFailure('Si è verificato un errore');
    //           });
    //         }
    //       },
    //     },
    //   );
    // },
  },
};
</script>

<style scoped>

</style>
